import React from "react"
import Helmet from "react-helmet"

import Layout from "../layouts/es"
import devp from "../../static/devp.png"
import SPHeader from "../components/static-pages-layout/static-page-header"
import Spp from "../components/static-pages-layout/static-page-p"
import SPSection from "../components/static-pages-layout/static-page-section"

const AcercaDeDEVPPage = ({ location }) => {
  const langPaths = { en: "/about-devp" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>Acerca de DEVP</title>
        <meta
          name="description"
          content="DevLand Points son el token principal de DevLand"
        />
      </Helmet>
      <SPHeader
        superTitle="Acerca de"
        title="DevLand Points (DEVP)"
        imageLight={devp}
        imageDark={devp}
      >
        Los DevLand Points son el token principal emitido por DevLand. El máximo
        total de DEVP es 1 millón y ya se emitió. Se usa como token de
        gobernanza sobre DevLand, como recompensa por colaborar en la
        construcción de la plataforma y como saldo para desarrollar proyectos.
      </SPHeader>
      <SPSection title="Información del activo">
        <Spp>
          Código del activo:{" "}
          <a
            href="https://stellar.expert/explorer/public/asset/DEVP-GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR-1"
            target="_blank"
            rel="noreferrer"
          >
            DEVP
          </a>
        </Spp>
        <Spp>
          Cuenta emisora:{" "}
          <a
            href="https://stellar.expert/explorer/public/account/GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR"
            target="_blank"
            rel="noreferrer"
          >
            GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR
          </a>
        </Spp>
        <Spp>Cantidad total: Fija, 1.000.000 DEVP</Spp>
      </SPSection>
      <SPSection title="Tokenomics">
        <table>
          <tr>
            <td>Cuenta de distribución</td>
            <td>Uso pendiente de ser decidido por la comunidad</td>
            <td>529.000 DEVP</td>
          </tr>
          <tr>
            <td>Fondo DevLand Network</td>
            <td>Para construir la primera fase</td>
            <td>50.000 DEVP</td>
          </tr>
          <tr>
            <td>Fondo DevLand Academy</td>
            <td>Para construir la segunda fase</td>
            <td>50.000 DEVP</td>
          </tr>
          <tr>
            <td>Fondo DevLand WorkEngine</td>
            <td>Para construir la tercera fase</td>
            <td>100.000 DEVP</td>
          </tr>
          <tr>
            <td>Preventa</td>
            <td>Venta anticipada del token</td>
            <td>150.000 DEVP</td>
          </tr>
          <tr>
            <td>Concursos & Recompensas</td>
            <td>
              Para recompensar a los usuarios activos por promociones y
              concursos
            </td>
            <td>20.000 DEVP</td>
          </tr>
          <tr>
            <td>Bono de bienvenida</td>
            <td>
              Para recompensar a nuestros primeros 500 usuarios con 1 DEVP
            </td>
            <td>500 DEVP</td>
          </tr>
          <tr>
            <td>Liquidez</td>
            <td>Para piscinas de liquidez</td>
            <td>100.000 DEVP</td>
          </tr>
        </table>
      </SPSection>
      <SPSection title="Preventa">
        <Spp>
          150.000 DevLand Points fueron puestos a la venta como oferta inicial
          de moneda en el DEX de Stellar, en varios bloques de 20k y un último
          de 50k DEVP. A partir de 5 XLM cada uno en el primer bloque, y
          terminando a 10 XLM en el último.
        </Spp>
        <Spp>
          Esta oferta no supone para el usuario la compra de participaciones o
          acciones. Los usuarios compran derechos de uso sobre la plataforma
          como las votaciones no vinculantes o la capacidad de producir
          proyectos de software.
        </Spp>
      </SPSection>
      <SPSection title="Aviso">
        <Spp>
          DEVP no es un producto de inversión. No hay activos reales que
          respalden su valor.
        </Spp>
        <Spp>
          DEVP no representa acciones o participaciones de DevLand. La capacidad
          de voto asociada no es vínculante.
        </Spp>
        <Spp>
          Sus funciones específicas son reconocer y recompensar a los miembros
          que colaboren en la construcción de la plataforma dándoles voz, y la
          de servir como medio de pago para DevLand WorkEngine permitiendoles
          crear sus propios proyectos.
        </Spp>
        <Spp>
          No podemos modificar la cantidad máxima de DEVP, ya que la cuenta
          emisora fue bloqueada para garantizar una cantidad fija. Si por alguna
          razón esto se convirtiera en un problema en el futuro, tras consultar
          a los usuarios mediante votaciones a través de DEVP, se podrán buscar
          e implementar soluciones como emitir un token que lo represente en
          ratio 1:1 y no esté bloqueado.
        </Spp>
      </SPSection>
    </Layout>
  )
}
export default AcercaDeDEVPPage
